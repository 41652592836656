<template>
  <section class="CardInstitution">
    <img class="mb-5" :src="require('@/assets/img/logo-institution.png')" alt="logo" />
    <div class="CardInstitution__title mb-1">Менеджмент инновационных технологий в аэрокосмическом комплексе</div>
    <div class="CardInstitution__desc mb-4">Курс повышения квалификации</div>
    <div class="CardInstitution__info">
      <span class="CardInstitution__price">500 000 ₽</span>
      <span class="CardInstitution__desc">Дистанционная</span>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CardInstitution'
}
</script>

<style lang="scss">
.CardInstitution{
  background-color: #F6F9FE;
  border-radius: 16px;
  padding: 20px;
  &__title{
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    color: #04153E;
  }
  &__desc{
    font-size: 13px;
    line-height: 16px;
    color: #04153E;
    opacity: 0.72;
  }
  &__info{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  &__price{
    color: #3D75E4;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
  }
}
</style>
